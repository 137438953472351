import gsap, { Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Power4);

export default function scrollEffect() {
  const items = gsap.utils.toArray(".js-fadein");

  items.forEach((item) => {
    gsap.fromTo(
      item,
      {
        y: 5,
        autoAlpha: 0,
      },
      {
        y: 0,
        autoAlpha: 1,
        delay: 0.4,
        duration: 1.2,
        ease: "power4.out",
        scrollTrigger: {
          trigger: item,
          start: "top 95%",
        },
      }
    );
  });

  const trigger = gsap.utils.toArray(".js-trigger");

  trigger.forEach((elem) => {
    ScrollTrigger.create({
      trigger: elem,
      start: "top 95%",
      toggleClass: { targets: elem, className: "js-active" },
      once: true,
    });
  });

  const title = gsap.utils.toArray(".js-title");

  title.forEach((elem) => {
    ScrollTrigger.create({
      trigger: elem,
      start: "top 95%",
      toggleClass: { targets: elem, className: "js-active" },
      once: true,
    });
  });

  const staggers = gsap.utils.toArray(".js-stagger");

  staggers.forEach((stagger) => {
    gsap.fromTo(
      ".js-slider_item",
      {
        y: 5,
        autoAlpha: 0,
      },
      {
        y: 0,
        autoAlpha: 1,
        delay: 0.4,
        duration: 1.2,
        ease: "power4.out",
        stagger: {
          amount: 1,
        },
        scrollTrigger: {
          trigger: stagger,
          start: "top 95%",
        },
      }
    );
  });

  let mm = gsap.matchMedia();

  mm.add("(max-width: 1024px)", () => {
    gsap.utils.toArray(".js-parallax").forEach((wrap) => {
      const y = wrap.getAttribute("data-y") || 200;

      gsap.to(wrap, {
        y: y,
        scrollTrigger: {
          trigger: wrap,
          start: "top 90%",
          end: "bottom top",
          scrub: 0.1,
        },
      });
    });
  });

  mm.add("(min-width: 1025px)", () => {
    gsap.utils.toArray(".js-parallax").forEach((wrap) => {
      const y = wrap.getAttribute("data-y") || 200;

      gsap.to(wrap, {
        y: y,
        scrollTrigger: {
          trigger: wrap,
          start: "top 90%",
          end: "bottom top",
          scrub: 0.5,
        },
      });
    });
  });
}
