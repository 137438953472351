export default function headerHeight() {
  //初期設定
  const options = {
    fixClass: "is-fix",
    hideClass: "is-hide",
    showClass: "is-show",
    showDelay: 500,
  };

  options.startPosi = 0;
  options.upFlg = false;
  options.setTimeoutFlg = false;
  options.setTimeoutId = 0;

  window.addEventListener("resize", function () {
    headerFix(options);
  });
  window.addEventListener("scroll", function () {
    headerFix(options);
  });

  //追従設定
  function headerFix(options) {
    const headerBasePosi = 0;
    const headerFixPosi = document.getElementById("head").offsetHeight + 100;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const fixClass = options.fixClass;
    const showClass = options.showClass;
    const hideClass = options.hideClass;

    if (scrollTop > options.startPosi) {
      options.upFlg = false;
      clearTimeout(options.setTimeoutId);
      options.setTimeoutFlg = false;
      if (scrollTop > headerFixPosi) {
        //追従class追加
        header.classList.add(fixClass);
        header.classList.add(hideClass);
      }

      const pageHeight = document.documentElement.scrollHeight;
      const scrollBottom = window.innerHeight + scrollTop;
      if (pageHeight <= scrollBottom) {
        headerShow(hideClass, showClass);
      } else {
        if (header.classList.contains(showClass)) {
          headerHide(hideClass, showClass);
        }
      }
    } else {
      options.upFlg = true;
      if (scrollTop <= headerFixPosi) {
        header.classList.remove(fixClass, showClass, hideClass);
      } else {
        if (!options.setTimeoutFlg) {
          options.setTimeoutFlg = true;
          options.setTimeoutId = setTimeout(function () {
            if (options.upFlg && header.classList.contains(fixClass)) {
              headerShow(hideClass, showClass);
            }
          }, options.showDelay);
        }
      }
    }

    options.startPosi = scrollTop;
  }

  function headerShow(hideClass, showClass) {
    header.classList.remove(hideClass);
    header.classList.add(showClass);
  }

  function headerHide(hideClass, showClass) {
    header.classList.remove(showClass);
    header.classList.add(hideClass);
  }
}
