import "../sass/style.scss";
import * as header from "./header";
import headerHeight from "./headerHeight";
import scrollEffect from "./scrollEffect";
import slider from "./slider";
import smallResponsive from "./smallResponsive";
import postRss from "./post";
import newsRss from "./news";

postRss();
newsRss();
smallResponsive();

function switchByWidth() {
  if (window.matchMedia("(min-width: 1280px)").matches) {
    headerHeight();
  }
}

window.onload = switchByWidth;
window.onresize = switchByWidth;

const kvHeight = document.getElementById("head").offsetHeight + 100;
const head = document.getElementsByClassName("header -top");

window.addEventListener("resize", function () {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  if (scrollTop > kvHeight) {
    head[0].classList.add("is-active");
  } else {
    head[0].classList.remove("is-active");
  }
});

window.addEventListener("scroll", function () {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  if (scrollTop > kvHeight) {
    head[0].classList.add("is-active");
  } else {
    head[0].classList.remove("is-active");
  }
});

slider();
header.toggleMenu();
scrollEffect();

// ウィンドウの読み込みが完了したとき
window.addEventListener("load", function () {
  document.body.classList.remove("fade");
});

// DOMが準備できたとき（$(function() {...} の代替）
// document.addEventListener("DOMContentLoaded", function () {
//   // ハッシュリンク(#)と別ウィンドウでページを開く場合はスルー
//   var links = document.querySelectorAll('a:not([href^="#"]):not([target])');
//   links.forEach(function (link) {
//     link.addEventListener("click", function (e) {
//       e.preventDefault(); // ナビゲートをキャンセル
//       var url = this.getAttribute("href"); // 遷移先のURLを取得
//       if (url !== "") {
//         document.body.classList.add("fade"); // bodyに class="fadeout"を挿入
//         setTimeout(function () {
//           window.location = url; // 0.8秒後に取得したURLに遷移
//         }, 600);
//       }
//       return false;
//     });
//   });
// });
