import { gsap } from "gsap";

// const NAV = document.getElementById("nav");
const BODY = document.querySelector("html");
const MENU = document.getElementById("menu");
const MENUITEM = document.querySelectorAll(".js-spMenu");
const NAV = document.getElementById("nav");

// ボタン連打用のフラグ
export let isAnimating = false;

export function menuOpen() {
  if (isAnimating) return;
  isAnimating = true;
  gsap
    .timeline({
      onStart: () => {
        MENU.classList.add("open");
        NAV.classList.add("open");
        BODY.classList.add("menu-open");
      },
      onComplete: () => {
        NAV.setAttribute("aria-hidden", "false");
        NAV.setAttribute("aria-expanded", "true");
        MENU.setAttribute("aria-label", "メニューを閉じる");
        MENU.setAttribute("aria-expanded", "true");
        MENUITEM.forEach(function (elem) {
          elem.removeAttribute("tabindex");
        });
        isAnimating = false;
      },
    })
    .set(".js-spMenuText", {
      y: 100,
      opacity: 0,
    })
    .to(
      ".js-spMenuWrap",
      {
        duration: 1,
        opacity: 1,
      },
      "-=0.5"
    )
    .to(
      ".js-spMenuText",
      {
        duration: 1.5,
        y: 0, // 少し上に移動させる
        opacity: 1,
        ease: "power3.out",
        stagger: {
          amount: 0.4,
        },
      },
      "-=0.8"
    );
}

export function menuClose() {
  if (isAnimating) return;
  isAnimating = true;
  gsap
    .timeline({
      onStart: () => {
        MENU.classList.remove("open");
        NAV.classList.remove("open");
        BODY.classList.remove("menu-open");
      },
      onComplete: () => {
        MENU.setAttribute("aria-label", "メニューを開く");
        MENU.setAttribute("aria-expanded", "false");
        NAV.setAttribute("aria-hidden", "true");
        NAV.setAttribute("aria-expanded", "false");
        MENUITEM.forEach(function (elem) {
          elem.setAttribute("tabindex", "-1");
        });
        isAnimating = false;
      },
    })
    .to(".js-spMenuWrap", {
      y: 0,
      opacity: 0,
    });
}

// タッチデバイスでは touchstart をトリガーにする
const clickTouchEvent = "ontouchstart" in window ? "touchstart" : "click";

// リスナー登録
export function toggleMenu() {
  MENU.addEventListener(clickTouchEvent, () => {
    if (MENU.getAttribute("aria-expanded") === "true") {
      menuClose();
    } else {
      menuOpen();
    }
  });

  MENUITEM.forEach((elem) => {
    elem.addEventListener("click", () => {
      menuClose();
    });
  });
}
