import Swiper, { Navigation, Pagination, EffectFade, Autoplay } from "swiper";

// import styles bundle
import "swiper/css/bundle";

const optionDefault = {
  modules: [EffectFade, Pagination, Autoplay],
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
  speed: 1200,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  loop: true,
  autoplay: {
    delay: 2000,
  },
};

const optionloop1 = {
  modules: [Autoplay],
  slidesPerView: 3.5,
  initialSlide: 1,
  speed: 8000,
  allowTouchMove: false,
  loop: true,
  autoplay: {
    delay: 0,
  },
};

const optionloop2 = {
  modules: [Autoplay],
  slidesPerView: 3.5,
  initialSlide: 2,
  speed: 10000,
  allowTouchMove: false,
  loop: true,
  autoplay: {
    delay: 0,
  },
};

const thumbPhoto1 = document.querySelectorAll(".photograph_thumb01 .media-md");
const thumbPhoto2 = document.querySelectorAll(".photograph_thumb02 .media-md");
const thumbPhoto3 = document.querySelectorAll(".photograph_thumb03 .media-md");
const thumbPhoto4 = document.querySelectorAll(".photograph_thumb04 .media-md");

const switchThumbPhoto1 = (index) => {
  document.querySelector(".photograph_thumb01 .thumb-active").classList.remove("thumb-active");
  thumbPhoto1[index].classList.add("thumb-active");
};
const switchThumbPhoto2 = (index) => {
  document.querySelector(".photograph_thumb02 .thumb-active").classList.remove("thumb-active");
  thumbPhoto2[index].classList.add("thumb-active");
};
const switchThumbPhoto3 = (index) => {
  document.querySelector(".photograph_thumb03 .thumb-active").classList.remove("thumb-active");
  thumbPhoto3[index].classList.add("thumb-active");
};
const switchThumbPhoto4 = (index) => {
  document.querySelector(".photograph_thumb04 .thumb-active").classList.remove("thumb-active");
  thumbPhoto4[index].classList.add("thumb-active");
};

const optionPhoto1 = {
  modules: [Navigation, EffectFade],
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
  speed: 500,
  on: {
    afterInit: (swiper) => {
      thumbPhoto1[swiper.realIndex].classList.add("thumb-active");
      for (let i = 0; i < thumbPhoto1.length; i++) {
        thumbPhoto1[i].onclick = () => {
          swiper.slideTo(i);
        };
      }
    },
    slideChange: (swiper) => {
      switchThumbPhoto1(swiper.realIndex);
    },
  },
};

const optionPhoto2 = {
  modules: [Navigation, EffectFade],
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
  speed: 500,
  on: {
    afterInit: (swiper) => {
      thumbPhoto2[swiper.realIndex].classList.add("thumb-active");
      for (let i = 0; i < thumbPhoto2.length; i++) {
        thumbPhoto2[i].onclick = () => {
          swiper.slideTo(i);
        };
      }
    },
    slideChange: (swiper) => {
      switchThumbPhoto2(swiper.realIndex);
    },
  },
};

const optionPhoto3 = {
  modules: [Navigation, EffectFade],
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
  speed: 500,
  on: {
    afterInit: (swiper) => {
      thumbPhoto3[swiper.realIndex].classList.add("thumb-active");
      for (let i = 0; i < thumbPhoto3.length; i++) {
        thumbPhoto3[i].onclick = () => {
          swiper.slideTo(i);
        };
      }
    },
    slideChange: (swiper) => {
      switchThumbPhoto3(swiper.realIndex);
    },
  },
};

const optionPhoto4 = {
  modules: [Navigation, EffectFade],
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
  speed: 500,
  on: {
    afterInit: (swiper) => {
      thumbPhoto4[swiper.realIndex].classList.add("thumb-active");
      for (let i = 0; i < thumbPhoto4.length; i++) {
        thumbPhoto4[i].onclick = () => {
          swiper.slideTo(i);
        };
      }
    },
    slideChange: (swiper) => {
      switchThumbPhoto4(swiper.realIndex);
    },
  },
};

export default function slider() {
  const swipergallery01 = new Swiper(".gallery_slider_list01", optionloop1);
  const swipergallery02 = new Swiper(".gallery_slider_list02", optionloop2);
  const swiperRoom01 = new Swiper(".rooms_contents_img01", optionDefault);
  const swiperRoom02 = new Swiper(".rooms_contents_img02", optionDefault);
  const swiperRoom03 = new Swiper(".rooms_contents_img03", optionDefault);
  const swiperRoom04 = new Swiper(".rooms_contents_img04", optionDefault);
  const swiperRoom05 = new Swiper(".rooms_contents_img05", optionDefault);
  const swiperCharm01 = new Swiper(".charm_img01", optionDefault);
  const swiperCharm02 = new Swiper(".charm_img02", optionDefault);
  const swiperCharm03 = new Swiper(".charm_img03", optionDefault);
  const swiperFood01 = new Swiper(".food_img01", optionDefault);
  const swiperFood02 = new Swiper(".food_img02", optionDefault);
  const swiperFood03 = new Swiper(".food_img03", optionDefault);
  const swiperBath01 = new Swiper(".bath_img01", optionDefault);
  const swiperBath02 = new Swiper(".bath_img02", optionDefault);
  const swiperBath03 = new Swiper(".bath_img03", optionDefault);
  const swiperPhoto01 = new Swiper(".photograph_slider01", optionPhoto1);
  const swiperPhoto02 = new Swiper(".photograph_slider02", optionPhoto2);
  const swiperPhoto03 = new Swiper(".photograph_slider03", optionPhoto3);
  const swiperPhoto04 = new Swiper(".photograph_slider04", optionPhoto4);
}
